<template>
    <div class="margin-90 mt20">
        <div class="function-dom column-flex-around" @click="jump(1)">
            <div class="function-icon margin-auto iconfont text-center" style="color: #74adca">&#xe692;</div>
            <div class="function-name font14">帮你记</div>
        </div>
    </div>
    <tab-bar></tab-bar>
</template>

<script>
    import TabBar from "../components/tabBar/tabBar";
    import {postFormAPI} from '@net/home'

    export default {
        name: "home",
        components: {TabBar},
        props:{
            request:Object
        },
        methods:{
          jump(type){
              if(type==1){
                  this.$router.push('/blotter_list')
              }
          }
        },
        created() {
            //this.$message({message:'success',center:true})
            //postFormAPI({aa:123,bb:456}).then(res => console.log(res))
        }
    }
</script>

<style scoped>
    .function-icon{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #eee;
        line-height: 40px;

        font-size: 23px;
    }
    .function-dom{
        height: 60px;
        width: 20%;
        align-items: center;
    }
</style>
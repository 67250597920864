//api签名验证函数
import md5 from "js-md5"
export default function createSign(obj) {
  //const env = process.env.TARO_ENV
  let key, secret
  key = "lm2021491024040"
  secret = "5e0e54e50156d7bd42ab8d6a6141bd79"

  //sign防上次重复
  delete obj.sign
  const time = Date.parse(new Date()) / 1000
  let keyAndValue = ""
  obj.key = key
  obj.time = time
  const keys = Object.keys(obj).sort()
  for (let i of keys) {
    keyAndValue += i + obj[i]
  }
  const signRaw = secret + keyAndValue + secret
  const signMd5 = md5(signRaw)
  obj.sign = signMd5
  return obj
}




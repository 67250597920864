import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn.mjs'
import Utils from '@u/utils'
import vant from 'vant'
import 'vant/lib/index.css'
import BaiduCalendar from 'vue-baidu-calendar'
//import axios from 'axios'
const app = createApp(App)
//app.config.globalProperties.Axios=axios
app.config.globalProperties.Utils=Utils
app.use(store).use(router).use(ElementPlus,{locale:zhCn}).use(vant).use(BaiduCalendar).mount('#app')
<template>
    <router-view></router-view>
</template>

<script>

  export default {

    name: 'App',
    components: {

    },
  }
</script>

<style lang="scss">
  @import "./assets/iconfont/iconfont.css";
  @import "~css/uni.scss";
  @import "~css/custom.css";
</style>

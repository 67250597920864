import LocalStorage from '@/localStorage/localstorage'
import {ElMessage} from 'element-plus'
import router from "../router";

let timeout = null
export default {
  has_login() {
    let token = LocalStorage.fetch('openid')
    if (token == undefined || token == '') {
      return false
    } else {
      return true
    }
  },
  Toast(type, msg, duration = 1000) {
    if (type == 'error') {
      ElMessage.error({message: msg, duration})
    } else if (type == 'success') {
      ElMessage.success({message: msg, duration})
    } else {
      ElMessage({message: msg, duration})
    }
  },
  public_var(type) {
    if (type == 'appid') {
      return 'U0QySTlPTDk3d284SWV5RUNYWG1RZGNTWjdtQQ=='
    } else if (type == 'd_img') {
      return 'https://www.lmlg.top/static/img/public/logo.png'
    } else if (type == 'map_ak') {
      return 'VhMdqUcvutFpCdrW83BpYVRMXdGyhCju'
    } else if (type == 'openid') {
      let token = LocalStorage.fetch('openid')
      return token
    }else if(type=='domain'){
      return 'https://lmlg.top'
    }else if(type=='web_key'){
      return 'lm2021491024040958611'
    }
  },
  /**
   * @author Gan
   * @date 2021/10/25 10:19
   * @Description: 跳转登录
   */
  go_login(url, query) {
    LocalStorage.save('login_back_query', JSON.stringify(query))
    LocalStorage.save('login_back_url', url)
    router.replace('/login')
  },
  /**
   * @author Gan
   * @date 2021/10/25 10:29
   * @Description:  登录成功返回
   */
  login_back() {
    let times = setTimeout(() => {
      let query = LocalStorage.fetch('login_back_query')
      let url = LocalStorage.fetch('login_back_url')
      if (url == '') {
        router.replace('/home')
      } else {
        let obj = JSON.parse(query)
        if (Object.keys(obj).length != 0) {
          let arr = []
          for (let k in obj) {
            arr.push(k + '=' + obj[k])
          }
          url += '?' + arr.join('&')
        }
        router.replace(url)
      }
      clearTimeout(times)
    }, 1000)
  },
  /**
   *@author: Gan
   *@date 2021/12/6 9:33
   *@Description: 标准时间转化
   * @type: 时间类型 2时间戳
   */
  formatDate(time, type) {
    let date = time
    if (type == 2) {
      date = new Date(time);
    }
    let YY = date.getFullYear() + '-';
    let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return YY + MM + DD + " " + hh + mm + ss;
  },
  debounce(fn) {//防抖
    if (timeout) {
      clearTimeout(timeout)
    }
    // 每当用户输入的时候把前一个 setTimeout clear 掉
    timeout = setTimeout(() => { // 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 fn 函数
      fn.apply(this, arguments)
      clearTimeout(timeout)
    }, 800)
  }
}
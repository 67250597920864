export default {
  fetch(key) {
    return window.localStorage.getItem(key) || ''
  },
  save(key, items) {
    window.localStorage.setItem(key, items)
  },
  remove_val(key) {
    window.localStorage.removeItem(key)
  }
}
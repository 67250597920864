import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'

import Home from '@v/home'
//import AffairEdit from '@v/blotter/affair_edit'

const Mine = () => import('@v/mine');
const AffairEdit = () => import('@v/blotter/affair_edit');
const BlotterIndex = () => import('@v/blotter/index');
const BlotterList = () => import('@v/blotter/blotter_list');
const Login = () => import('@v/login/login');
const RegByEmail = () => import('@v/login/registerByEmail');
const chatRoom = () => import('@v/chat_room_dom');
const Test=()=>import('@v/test');
const routes = [
  {
    path: '/home',
    component: Home,
    meta: {
      title: '首页'
    },
  },
  {
    path: '/mine',
    component: Mine,
    meta: {
      title: '个人中心'
    },
  },
  {
    path: '/blotter_list',
    component: BlotterList,
    meta: {
      title: '我的事件簿'
    },
  },
  {
    path: '/affair_edit',
    component: AffairEdit,
    meta: {
      title: '事件编辑'
    },
  },
  {
    path: '/blotter_index',
    component: BlotterIndex,
    meta: {
      title: '帮你记'
    },
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: '登录'
    },
  },
  {
    path: '/register_email',
    component: RegByEmail,
    meta: {
      title: '邮箱注册'
    },
  },
  {
    path: '/chat_room',
    component: chatRoom,
    meta: {
      title: '聊天室'
    }
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path:'/test',
    component: Test,
    meta: {
      title: '测试'
    }
  }
]

const router = createRouter({
  //mode:history,
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.matched[0].meta.title
  next()
})
export default router

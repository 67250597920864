<template>
    <div class="around py10 tab-bar">
        <tab-bar-item v-for="(item,index) in tab_array" :data="item" :key="index"></tab-bar-item>
    </div>
</template>

<script>
    import TabBarItem from "./tabBarItem";
    export default {
        name: "tabBar",
        components: {TabBarItem},
        data(){
            return{
                tab_array:[
                    {
                        icon: 'icon-home',
                        tab_name: '首页',
                        default_color:'#999',
                        active_color:'#89e7c6',
                        path:'/home'
                    },
                    {
                        icon: 'icon-mine',
                        tab_name: '我的',
                        default_color: '#999',
                        active_color:'#89e7c6',
                        path:'/mine'
                    },
                ]
            }

        },
    }
</script>

<style>
    .tab-bar{
        position: fixed;
        bottom: 0;
        width: 100%;
    }
</style>
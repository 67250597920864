/****   request.js   ****/
// 导入axios
import axios from 'axios'
import { ElMessage } from 'element-plus'
import localStore from '../localStorage/localstorage'
import sign from "./sign";
axios.defaults.withCredentials=true;
// 2.请求拦截器
axios.interceptors.request.use(config => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
     //数据转化,也可以使用qs转换
    config.defaults={withCredentials:true}//让ajax携带cookie
    return config
}, error => {
    Promise.reject(error)
})

// 3.响应拦截器
axios.interceptors.response.use(response => {

    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    return response.data
}, error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
            case 400:
                error.message = '错误请求'
                break;
            case 401:
                error.message = '未授权，请重新登录'
                break;
            case 403:
                error.message = '拒绝访问'
                break;
            case 404:
                error.message = '请求错误,未找到该资源'
                //window.location.href = "/NotFound" //请求错误
                break;
            case 405:
                error.message = '请求方法未允许'
                break;
            case 408:
                error.message = '请求超时'
                break;
            case 500:
                error.message = '服务器端出错'
                break;
            case 501:
                error.message = '网络未实现'
                break;
            case 502:
                error.message = '网络错误'
                break;
            case 503:
                error.message = '服务不可用'
                break;
            case 504:
                error.message = '网络超时'
                break;
            case 505:
                error.message = 'http版本不支持该请求'
                break;
            default:
                error.message = `连接错误${error.response.status}`
        }

    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            ElMessage.error('服务器响应超时，请刷新当前页')
        }
        error.message = '连接服务器失败'
    }

    ElMessage.error(error.message)
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略

    //return Promise.resolve(error.response)
    return error.message
})
//4.导入文件
//4.导入文件
//put请求
export const request=(type,params)=>{
    let method=params.method
    let base=''
    let header= {}
    let config={}
    if(params.url=='/open/img_verify/verify'){
        config.responseType='arraybuffer'
    }
    if(type=='/testApi'){
        if(process.env.VUE_APP_FLAG=='development'){
            base='/testApi'
        }else {
            base='https://www.lmlg.top'//正式地址
        }
        header['Content-Type']='application/json'//配置请求头
        if(method=='post'){//进行签名验证
            if(params.params.hasOwnProperty('is_login')){
                params.params.openid=localStore.fetch('openid')
            }
            params.params=sign(params.params)
            header['token']= params.params.sign; //如果要求携带在请求头中
            delete params.params.sign
            params.params= JSON.stringify(params.params);
        }

    }else if(type=='/mapApi'){
        base='/mapApi'

    }else if(type=='/myApi'){
      base='/myApi'
    }
    if(method=='post'){
        config.data=params.params
    }else if(method=='get'){
        config.params=params.params
    }

    config.url=`${base}${params.url}`
    config.method=method
    config.headers=header
    config.timeout=5*1000
    return axios(config);
}


<template>
    <div class="tab-item column-flex-between" :style="activeColor()" @click="tabClick()">
        <div class="iconfont tab-icon text-center"  :class="data.icon"
             ></div>
        <div class="tab-name text-center font14">{{data.tab_name}}</div>
    </div>
</template>

<script>
    export default {
        name: "tabBarItem",
        props:{
            data:{
                type:Object,
                default:{}
            },
        },
        data(){
            return{

            }
        },
        methods:{
            isActive(){
                return this.$route.path.indexOf(this.data.path) !== -1
            },
            activeColor(){
                return this.isActive() ? {color:this.data.active_color } :{color:this.data.default_color}
            },
            tabClick(){
                this.$router.replace(this.data.path)
            }
        },
        created() {

        }
    }
</script>

<style scoped>
    .tab-item{
        width: 50px;
        height: 100%;
    }
    .tab-icon{
        font-size: 25px;
    }
</style>